<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
            placeholder="Select Status.."
            :clearable="true"
          />
        </b-col>
        <b-col cols="12" md="4" sm="6" class="mb-md-0 mb-2">
          <label>Requested by</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="requestedByFilter"
            :options="employeeOptions"
            class="w-100"
            :reduce="(val) => val.code"
            @input="(val) => $emit('update:requestedByFilter', val)"
            placeholder="Select Requested By.."
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: (h) => h("feather-icon", { props: { size: "14", icon: "XIcon" } }),
  },
  OpenIndicator: {
    render: (h) =>
      h("feather-icon", {
        props: { size: "15", icon: "ChevronDownIcon" },
        class: "open-indicator",
      }),
  },
});

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    vSelect,
  },
  props: {
    statusFilter: {},
    statusOptions: {
      type: Array,
      required: true,
    },
    requestedByFilter: {},
    employeeOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
